var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-breadcrumb',{staticClass:"mb-4",attrs:{"items":_vm.breadcrumb}})],1)],1),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[(_vm.bots)?_c('vx-card',{staticClass:"overflow-hidden mb-2",attrs:{"title":_vm.lang.surveyIntegrations.bots.title[_vm.languageSelected]}},[_c('vs-table',{staticClass:"mt-4",attrs:{"pagination":"","max-items":"5","data":_vm.bots,"search":"","sorted":"","no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},on:{"selected":_vm.selectBot},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].name}},[_vm._v(_vm._s(data[indextr].name))]),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr]._createdAt}},[_vm._v(_vm._s(_vm._f("date")(new Date(data[indextr]._createdAt))))])],1)})}}]),model:{value:(_vm.selectedBot),callback:function ($$v) {_vm.selectedBot=$$v},expression:"selectedBot"}},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"name"}},[_vm._v(_vm._s(_vm.lang.surveyIntegrations.bots.table.bot[_vm.languageSelected]))]),_vm._v(" "),_c('vs-th',{attrs:{"sort-key":"_createdAt"}},[_vm._v("\n              "+_vm._s(_vm.lang.surveyIntegrations.bots.table.creation[_vm.languageSelected])+"\n            ")])],1)],2)],1):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"height":"10px"}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[(_vm.selectedBot && _vm.integrations)?_c('vx-card',{staticClass:"overflow-hidden mb-2",attrs:{"title":_vm.lang.surveyIntegrations.channels.title[_vm.languageSelected]}},[_c('vs-table',{staticClass:"mt-4",attrs:{"pagination":"","max-items":"5","data":_vm.integrations,"search":"","sorted":"","no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},on:{"selected":_vm.selectIntegration},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].type}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mr-3"},[_vm._v(_vm._s(data[indextr].type))]),_vm._v(" "),(data[indextr].element && data[indextr].element.version)?_c('vs-chip',{attrs:{"color":data[indextr].element.version.active? 'success':''}},[_vm._v("\n                    "+_vm._s(data[indextr].element.version.tag)+"\n                  ")]):_vm._e()],1)]),_vm._v(" "),(data[indextr]._createdAt)?_c('vs-td',{attrs:{"data":data[indextr]._createdAt}},[_vm._v(_vm._s(_vm._f("date")(new Date(data[indextr]._createdAt))))]):_c('vs-td',[_vm._v("N/A")])],1)})}}]),model:{value:(_vm.selectedIntegration),callback:function ($$v) {_vm.selectedIntegration=$$v},expression:"selectedIntegration"}},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"type"}},[_vm._v("\n              "+_vm._s(_vm.lang.surveyIntegrations.channels.table.channel[
                  _vm.languageSelected
                ])+"\n            ")]),_vm._v(" "),_c('vs-th',{attrs:{"sort-key":"_createdAt"}},[_vm._v("\n              "+_vm._s(_vm.lang.surveyIntegrations.channels.table.creation[
                  _vm.languageSelected
                ])+"\n            ")])],1)],2)],1):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"height":"10px"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[(_vm.selectedIntegration && (_vm.selectedIntegration.key === 'whatsapp' || _vm.selectedIntegration.key === 'whatsapp_infobip'))?_c('SurveyChannelWhatsapp',{attrs:{"survey":_vm.survey,"bot":_vm.selectedBot,"integration":_vm.selectedIntegration}}):_vm._e(),_vm._v(" "),(_vm.selectedIntegration && _vm.selectedIntegration.key === 'email')?_c('SurveyChannelEmail',{attrs:{"survey":_vm.survey,"bot":_vm.selectedBot,"integration":_vm.selectedIntegration,"survey-form":_vm.surveySettings && _vm.surveySettings.surveyForm}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }