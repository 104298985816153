<template>
  <div>
    <div class="vx-row">
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-breadcrumb class="mb-4" :items="breadcrumb"></vs-breadcrumb>
      </vs-col>
    </div>
    <div class="vx-row">
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vx-card
          v-if="bots"
          :title="lang.surveyIntegrations.bots.title[languageSelected]"
          class="overflow-hidden mb-2"
        >
          <vs-table
            class="mt-4"
            pagination
            max-items="5"
            v-model="selectedBot"
            @selected="selectBot"
            :data="bots"
            search
            sorted
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th sort-key="name">{{
                lang.surveyIntegrations.bots.table.bot[languageSelected]
              }}</vs-th>
              <vs-th sort-key="_createdAt">
                {{
                  lang.surveyIntegrations.bots.table.creation[languageSelected]
                }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">{{
                  data[indextr].name
                }}</vs-td>
                <vs-td :data="data[indextr]._createdAt">{{
                  new Date(data[indextr]._createdAt) | date
                }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
        <div style="height: 10px;"></div>
      </vs-col>

      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vx-card
          v-if="selectedBot && integrations"
          :title="lang.surveyIntegrations.channels.title[languageSelected]"
          class="overflow-hidden mb-2"
        >
          <vs-table
            class="mt-4"
            pagination
            max-items="5"
            v-model="selectedIntegration"
            @selected="selectIntegration"
            :data="integrations"
            search
            sorted
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th sort-key="type">
                {{
                  lang.surveyIntegrations.channels.table.channel[
                    languageSelected
                  ]
                }}
              </vs-th>
              <vs-th sort-key="_createdAt">
                {{
                  lang.surveyIntegrations.channels.table.creation[
                    languageSelected
                  ]
                }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].type">
                  <div class="flex">
                    <div class="mr-3">{{data[indextr].type}}</div>
                    <vs-chip v-if="data[indextr].element && data[indextr].element.version"
                             :color="data[indextr].element.version.active? 'success':''">
                      {{data[indextr].element.version.tag}}
                    </vs-chip>
                  </div>
                </vs-td>
                <vs-td
                  :data="data[indextr]._createdAt"
                  v-if="data[indextr]._createdAt"
                  >{{ new Date(data[indextr]._createdAt) | date }}</vs-td
                >
                <vs-td v-else>N/A</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
        <div style="height: 10px;"></div>
      </vs-col>
    </div>
    <div class="vx-row">
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <SurveyChannelWhatsapp
          v-if="selectedIntegration && (selectedIntegration.key === 'whatsapp' || selectedIntegration.key === 'whatsapp_infobip')"
          :survey="survey"
          :bot="selectedBot"
          :integration="selectedIntegration"
        />
        <SurveyChannelEmail
          v-if="selectedIntegration && selectedIntegration.key === 'email'"
          :survey="survey"
          :bot="selectedBot"
          :integration="selectedIntegration"
          :survey-form="surveySettings && surveySettings.surveyForm"
        />
      </vs-col>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import services from './services'

export default {
  components: {
    SurveyChannelWhatsapp: () => import('./channels/SurveyChannelWhatsapp'),
    SurveyChannelEmail: () => import('./channels/SurveyChannelEmail')
  },
  data() {
    return {
      breadcrumb: [],
      survey: null,
      bots: null,

      selectedBot: null,
      integrations: null,
      selectedIntegration: null,

      surveySettings: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    async selectIntegration(tr, doNotUpdateRouter) {
      if (!doNotUpdateRouter) {
        this.$router.push({
          query: {
            survey: this.$route.query.survey,
            service: this.$route.query.service,
            bot: this.$route.query.bot,
            integration: tr.key
          }
        })
      }
    },
    async selectBot(tr, doNotUpdateRouter) {
      if (!doNotUpdateRouter) {
        this.$router.push({
          query: {
            survey: this.$route.query.survey,
            service: this.$route.query.service,
            bot: tr._id
          }
        })
      }
      this.selectedIntegration = null
      await this.getBotIntegrations(tr.serviceId)
      this.getSurveySettings()
    },

    hasEmailSupport() {
      return this.surveySettings.smtp || this.surveySettings.sendgrid
    },

    async getSurveySettings() {
      this.$vs.loading()
      try {
        const result = await services.getSurveySettings()
        this.surveySettings = result.data

        // TODO: restore (removed to go to prod)
        if (this.hasEmailSupport()) {
          this.integrations.push({
            key: 'email',
            type: 'email',
            _createdAt: new Date()
          })
        }
      } catch (error) {
        // TODO: handle error
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyIntegrations.channels.messages.error
            .surveySettings[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      } finally {
        this.$vs.loading.close()
      }
    },

    async getBotIntegrations(serviceId) {
      this.$vs.loading()
      const result = await services.getBotIntegrations(serviceId)
      this.integrations = result.data
        .map(element => ({
          key: element.type,
          type: element.type,
          config: element.config,
          meta: element.meta,
          _createdAt: element._createdAt,
          _updatedAt: element._updatedAt,
          element
        }))
        .filter(element => ['whatsapp','whatsapp_infobip'].indexOf(element.key) > -1)

      // this.integrations.push({
      //   key: 'whatsapp',
      //   type: 'WhatsApp Testing',
      //   config: {},
      //   meta: {}
      // })
      this.$vs.loading.close()
    },
    async getBots() {
      try {
        const result = await services.getBots()
        return result.data.map(element => ({
          _id: element._id,
          serviceId: element.service._id,
          name: element.service.name,
          _createdAt:
            element._createdAt ||
            element.service._createdAt ||
            element._updatedAt ||
            element.service._updatedAt
        }))
      } catch (error) {
        // TODO: handle error
        this.$vs.notify({
          title: 'Oops!',
          text: 'Parece que ocurrió un error',
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    async getSurvey(surveyId) {
      try {
        const result = await services.getSurvey(surveyId)

        this.survey = result.data
      } catch (error) {
        // TODO: handle error
        this.$vs.notify({
          title: 'Oops!',
          text: 'Parece que ocurrió un error',
          color: 'danger',
          position: 'top-right'
        })
      }
    }
  },
  async created() {
    this.$vs.loading()
    await this.getSurvey(this.$route.query.survey)

    // TODO: translate breadcrumbs
    this.breadcrumb = [
      {
        title: 'Encuestas',
        url: '/#/surveys',
        active: false
      },
      {
        title: this.survey.service.name,
        url: `/#/surveys?survey=${this.$route.query.survey}`,
        active: false
      },
      {
        title: 'Enviar encuesta',
        active: true
      }
    ]

    this.bots = await this.getBots()

    if (this.$route.query.bot) {
      let i = null
      this.bots.some((bot, index) => {
        if (bot._id === this.$route.query.bot) {
          i = index
          return true
        }
      })
      if (i !== null) {
        this.selectedBot = this.bots[i]
        await this.selectBot(this.bots[i], true)
      }
    }

    if (this.$route.query.integration) {
      let i = null
      this.integrations.some((integration, index) => {
        if (integration.key === this.$route.query.integration) {
          i = index
          return true
        }
      })
      if (i !== null) {
        this.selectedIntegration = this.integrations[i]
        await this.selectIntegration(this.integrations[i], true)
      }
    }

    this.$vs.loading.close()
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>
