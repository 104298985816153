import axios from 'axios'
import store from '../../../store/store'
import Helper from '@/store/Helper'

const url = store.state.config.konecta

const _getHeaders = () => {
  const session = localStorage.getItem('session')
  const token = JSON.parse(session).token
  return {
    authorization: `Bearer ${token}`
  }
}

export default {
  // getChat(serviceId, senderId) {
  //   return axios({
  //     method: 'get',
  //     url:
  //       url + `/chat?where[service]=${serviceId}&where[senderId]=${senderId}`,
  //     headers
  //   })
  // },
  // async getIntegraton(integrationId) {
  //   await new Promise(resolve => {
  //     setTimeout(() => {
  //       resolve()
  //     }, 1000)
  //   })
  //   return { integrationId }
  // },

  // sendSurveyInActiveBotVersion(botId, data) {
  //   return axios({
  //     method: 'put',
  //     url: url + `/bot/${botId}/send-survey`,
  //     data,
  //     headers
  //   })
  // },

  // updateSurveyInActiveBotVersion(botId, data) {
  //   return axios({
  //     method: 'put',
  //     url: url + `/bot/${botId}/update-survey`,
  //     data,
  //     headers
  //   })
  // },

  // addSurveyToActiveBotVersion(botId, data) {
  //   return axios({
  //     method: 'put',
  //     url: url + `/bot/${botId}/add-survey`,
  //     data,
  //     headers
  //   })
  // },
  getBotIntegrations(serviceId) {
    return axios({
      method: 'get',
      url: url + `/integration?where[service]=${serviceId}`,
      headers: _getHeaders()
    })
  },

  getBots() {
    return axios({
      method: 'get',
      url: url + `/bot?select[service]=true`,
      headers: _getHeaders()
    })
  },
  // getcontexts(botId) {
  //   //return alert("aaaa");
  //   return axios({
  //     method: 'get',
  //     url: url + `/survey/contexts/${botId}`,
  //     headers
  //   })
  // },
  // getSurveyBotVersions(serviceId) {
  //   return axios({
  //     method: 'get',
  //     url:
  //       url + `/version?where[active]=true&where[surveys.service]=${serviceId}`,
  //     headers
  //   })
  // },
  getSurvey(surveyId) {
    return axios({
      method: 'get',
      url: url + `/survey/${surveyId}`,
      headers: _getHeaders()
    })
  },

  addSurvey(botId, data) {
    return axios({
      method: 'post',
      url: url + `/bot/${botId}/schedule-survey`,
      data: {
        ...data,
        timeOffsetUser: Helper.getBrowserTimeZone().text
      },
      headers: _getHeaders()
    })
  },

  getSurveySettings() {
    return axios({
      method: 'get',
      url: url + `/survey/settings`,
      headers: _getHeaders()
    })
  }
}
